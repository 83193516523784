import store from "services/store";
import i18n from "i18next";
import api from "services/api";
import { generatePath } from "react-router";

import historyService from "services/history";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing, isValidUrl, ApplyIf } from "services/validator/rules";

import createActions from "modules/form/actions";
import {
  packRegistriesActions,
  helmRegistriesActions,
  ociRegistriesActions,
} from "./list";
import {
  getSelectedPackRegistry,
  getSelectedHelmRegistry,
  getSelectedOCIRegistry,
} from "state/packregistries/selectors";

import {
  packRegistryFetcher,
  ociRepositoryFetcher,
  helmRegistryFetcher,
  packRegistryModal,
  helmRegistryModal,
  ociRegistryModal,
  PACK_MODULE,
  HELM_MODULE,
  OCI_MODULE,
} from "state/packregistries/services";
import { REGISTRIES, SETTINGS } from "utils/constants/routes";

export const ENDPOINT_TEMPLATE_STRING = "{{.spectro.system.aws.region}}";

export const validator = new Validator();
validator.addRule(["name", "endpoint"], Missing());
validator.addRule(["endpoint"], isValidUrl());
validator.addRule(["username", "password"], (value, key, data) => {
  if (data.noAuth) {
    return false;
  }
  return Missing()(value, key, data);
});

export function showDefaultRegion({ authType, endpoint } = {}) {
  return authType === "ecr" && endpoint.includes(ENDPOINT_TEMPLATE_STRING);
}

function getSubmitPayload({
  data: {
    name,
    endpoint,
    noAuth,
    username,
    password,
    authType,
    credentialType,
    isPrivate,
    defaultRegion,
    providerType,
    ca,
    insecureSkipTlsVerify,
    baseContentPath,
    helmBaseContentPath,
    enableSync,
    basePath: basicPath,
    ...rest
  },
  isDevMode = false,
  isOCI = false,
}) {
  const contentPath =
    providerType === "helm"
      ? enableSync
        ? helmBaseContentPath.join(",")
        : ""
      : baseContentPath;
  const basePath =
    providerType === "helm" && enableSync && authType === "basic"
      ? basicPath
      : "";
  const base = {
    metadata: {
      name,
    },
    spec: {
      name,
      endpoint,
      isPrivate,
      providerType,
      isSyncSupported: enableSync,
      defaultRegion: showDefaultRegion({ authType, endpoint })
        ? defaultRegion
        : undefined,
      ...(contentPath ? { baseContentPath: contentPath } : {}),
      ...(basePath ? { basePath } : {}),
      ...(isDevMode && { scope: "app" }),
      ...(isOCI &&
        authType !== "basic" && {
          tls: {
            ca,
            enabled: true,
            insecureSkipVerify: insecureSkipTlsVerify || false,
          },
        }),
    },
  };

  let auth = {
    type: noAuth ? "noAuth" : "basic",
    username: noAuth ? undefined : username,
    password: noAuth ? undefined : password,
    ...(!!base.spec.tls
      ? {}
      : {
          tls: {
            ca,
            enabled: true,
            insecureSkipVerify: insecureSkipTlsVerify || false,
          },
        }),
  };

  if (["helm", "pack"].includes(providerType) && authType !== "basic") {
    if (credentialType === "secret") {
      auth = {
        credentialType: "secret",
        accessKey: rest.accessKey,
        secretKey: rest.secretKey,
      };
    } else {
      auth = {
        credentialType: "sts",
        sts: {
          arn: rest.arn,
          externalId: rest.externalId,
        },
      };
    }
    if (!isPrivate) {
      auth = undefined;
    }
  }

  return {
    ...base,
    spec: {
      ...base.spec,
      ...(authType === "basic" ? { auth } : { credentials: auth }),
    },
  };
}

function getValidationPayload(formData, selectedRegistryId) {
  const isOciHelm = formData.providerType === "helm";
  const isSyncEnabled = formData.enableSync;
  const baseContentPath =
    isOciHelm && isSyncEnabled
      ? formData.helmBaseContentPath.join(",")
      : formData.baseContentPath;
  if (formData.authType === "ecr") {
    let auth = {
      credentialType: "sts",
      sts: {
        arn: formData.arn,
        externalId: formData.externalId,
      },
    };
    if (formData.credentialType === "secret") {
      auth = {
        credentialType: "secret",
        accessKey: formData.accessKey,
        secretKey: formData.secretKey,
      };
    }

    return {
      providerType: formData.providerType,
      endpoint: formData.endpoint,
      isPrivate: formData.isPrivate,
      credentials: auth,
      ...(baseContentPath ? { baseContentPath } : {}),
      isSyncSupported: formData.enableSync,
      ...(selectedRegistryId && { registryUid: selectedRegistryId }),
      tls: {
        ca: formData.ca,
        enabled: true,
        insecureSkipVerify: formData.insecureSkipTlsVerify || false,
      },
    };
  }

  return {
    providerType: formData.providerType,
    isSyncSupported: formData.enableSync,
    endpoint: formData.endpoint,
    ...(selectedRegistryId && { registryUid: selectedRegistryId }),
    ...(baseContentPath ? { baseContentPath } : {}),
    basePath: formData.basePath,
    auth: {
      username: formData.username,
      password: formData.password,
      type: "basic",
      tls: {
        ca: formData.ca,
        enabled: true,
        insecureSkipVerify: formData.insecureSkipTlsVerify || false,
      },
    },
  };
}

async function submit(data) {
  const selectedPackRegistry = getSelectedPackRegistry(store.getState());
  const payload = getSubmitPayload({ data });
  let response;
  try {
    response = selectedPackRegistry
      ? await api.put(
          `v1/registries/pack/${selectedPackRegistry.metadata.uid}`,
          payload
        )
      : await api.post("v1/registries/pack", payload);

    notifications.success({
      message: selectedPackRegistry
        ? i18n.t("Pack registry '{{name}}' was updated successfully", {
            name: data.name,
          })
        : i18n.t("Pack registry '{{name}}' was created successfully", {
            name: data.name,
          }),
    });
  } catch (error) {
    const message = selectedPackRegistry
      ? i18n.t("Something went wrong when editing a pack registry")
      : i18n.t("Something went wrong when creating a pack registry");

    notifications.error({
      message,
      description: error.message,
    });

    return Promise.reject();
  }

  if (selectedPackRegistry) {
    store.dispatch(packRegistriesActions.initialize(PACK_MODULE));
    historyService.push("/settings/registries/pack");
    return;
  }

  if (!response) {
    return;
  }

  historyService.push("/settings/registries/pack");
  store.dispatch(
    packRegistriesActions.addItems({
      module: PACK_MODULE,
      items: [
        {
          metadata: {
            name: data.name,
            uid: response.uid,
          },
          spec: {
            endpoint: data.endpoint,
          },
        },
      ],
    })
  );
}

async function init() {
  let data;
  if (packRegistryModal?.data.uid) {
    data = await store.dispatch(packRegistryFetcher.fetch());
  }

  return {
    authType: "basic",
    name: data?.metadata?.name || "",
    endpoint: data?.spec?.endpoint || "",
    username: data?.spec?.auth?.username || "",
    password: data?.spec?.auth?.password || "",
    isValid: !!packRegistryModal?.data?.uid,
    insecureSkipTlsVerify: data?.spec?.auth?.tls?.insecureSkipVerify || false,
  };
}

export function openPackRegistryModal(uid) {
  return (dispatch) => {
    packRegistryModal.open({ uid }).then(
      () => {
        return dispatch(
          packRegistryFormActions.submit({ module: PACK_MODULE })
        );
      },
      () => historyService.push("/settings/registries/pack")
    );
    dispatch(packRegistryFormActions.init({ module: PACK_MODULE }));
  };
}

export const packRegistryFormActions = createActions({
  init,
  submit,
  validator,
});

export function validatePackRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.packregistries?.data;

    if (!formData) {
      return;
    }

    const errors = await dispatch(
      packRegistryFormActions.validateForm({
        module: PACK_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const selectedRegistryId = getSelectedPackRegistry(store.getState())
      ?.metadata?.uid;

    const payload = getValidationPayload(formData, selectedRegistryId);
    const promise = api.post("v1/registries/pack/validate", payload);

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your pack registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onResetField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: PACK_MODULE };
    const isPackRegistryValidated = getState().registry.isValid;

    if (isPackRegistryValidated) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    dispatch(packRegistryFormActions.onChange(props));
  };
}

export const helmRegistryFormActions = createActions({
  init: async () => {
    let data;
    if (helmRegistryModal?.data?.uid) {
      data = await store.dispatch(helmRegistryFetcher.fetch());
    }
    return Promise.resolve({
      authType: "basic",
      name: data?.metadata?.name || "",
      endpoint: data?.spec?.endpoint || "",
      username: data?.spec?.auth?.username || "",
      password: data?.spec?.auth?.password || "",
      isPrivate: data?.spec?.isPrivate || false,
      isValid: !!helmRegistryModal?.data?.uid,
      noAuth: helmRegistryModal?.data?.uid
        ? data?.spec?.auth?.type === "noAuth"
        : true,
      persisted: !!data,
      insecureSkipTlsVerify: data?.spec?.auth?.tls?.insecureSkipVerify || false,
    });
  },
  submit: async (data) => {
    const selectedRegistry = getSelectedHelmRegistry(store.getState());
    const isDevMode = store.getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "helm" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "helm" });
    const payload = getSubmitPayload({ data, isDevMode });

    let response;
    try {
      response = selectedRegistry
        ? await api.put(
            `v1/registries/helm/${selectedRegistry.metadata.uid}`,
            payload
          )
        : await api.post("v1/registries/helm", payload);

      notifications.success({
        message: selectedRegistry
          ? i18n.t("Helm registry '{{name}}' was updated successfully", {
              name: data.name,
            })
          : i18n.t("Helm registry '{{name}}' was created successfully", {
              name: data.name,
            }),
      });
    } catch (error) {
      const message = selectedRegistry
        ? i18n.t("Something went wrong when editing a helm registry")
        : i18n.t("Something went wrong when creating a helm registry");

      notifications.error({
        message,
        description: error.message,
      });

      return Promise.reject();
    }

    if (selectedRegistry) {
      store.dispatch(helmRegistriesActions.initialize(HELM_MODULE));
      historyService.push(returnPath);
      return;
    }

    if (!response) {
      return;
    }

    historyService.push(returnPath);
    store.dispatch(
      helmRegistriesActions.addItems({
        module: HELM_MODULE,
        items: [
          {
            metadata: {
              name: data.name,
              uid: response.uid,
            },
            spec: {
              endpoint: data.endpoint,
            },
          },
        ],
      })
    );
  },
  validator,
});

export function openHelmRegistryModal(uid) {
  return (dispatch, getState) => {
    const isDevMode = getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "helm" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "helm" });

    helmRegistryModal.open({ uid }).then(
      () => {
        return dispatch(
          helmRegistryFormActions.submit({ module: HELM_MODULE })
        );
      },
      () => historyService.push(returnPath)
    );
    dispatch(helmRegistryFormActions.init({ module: HELM_MODULE }));
  };
}

export function validateHelmRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.helmregistries?.data;
    if (!formData) {
      return;
    }

    const errors = await dispatch(
      helmRegistryFormActions.validateForm({
        module: HELM_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const selectedRegistryId = getSelectedHelmRegistry(store.getState())
      ?.metadata?.uid;

    const payload = getValidationPayload(formData, selectedRegistryId);
    const promise = api.post("v1/registries/helm/validate", payload);

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your helm registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onHelmChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: HELM_MODULE };
    const formData = getState().forms?.helmregistries?.data;

    let invalidate = getState().registry.isValid;
    if (formData.isPrivate) {
      invalidate = false;
    }

    if (invalidate) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    if (name === "isPrivate") {
      if (value) {
        dispatch({
          type: "VALIDATE_REGISTRY_SUCCESS",
        });
      } else {
        dispatch({
          type: "SET_INVALID_REGISTRY",
        });
      }
    }

    dispatch(helmRegistryFormActions.onChange(props));
  };
}

export const ociValidator = new Validator();
ociValidator.addRule(["name", "endpoint"], Missing());
ociValidator.addRule(
  ["username", "password"],
  ApplyIf(
    (value, key, data) =>
      (data.providerType === "helm" && data.authType === "basic") ||
      (data.providerType === "zarf" && !data.noAuth),
    Missing()
  )
);
ociValidator.addRule(
  ["helmBaseContentPath"],
  ApplyIf(
    (_1, _2, data) => data?.providerType === "helm" && data?.enableSync,
    Missing()
  )
);

ociValidator.addRule(
  ["arn", "externalId"],
  ApplyIf(
    (value, key, data) =>
      data.authType === "ecr" &&
      data.isPrivate &&
      data.credentialType === "sts",
    Missing()
  )
);

ociValidator.addRule(
  ["accessKey", "secretKey"],
  ApplyIf(
    (value, key, data) =>
      data.authType === "ecr" &&
      data.isPrivate &&
      data.credentialType === "secret",
    Missing()
  )
);

ociValidator.addRule(
  ["defaultRegion"],
  ApplyIf((value, key, data) => showDefaultRegion(data), Missing())
);

export const ociRegistryFormActions = createActions({
  init: async () => {
    let data;
    if (ociRegistryModal?.data?.uid) {
      data = await store.dispatch(ociRepositoryFetcher.fetch());
    }

    const stsData = await api.get("v1/clouds/aws/account/sts");

    return Promise.resolve({
      name: data?.metadata?.name || "",
      endpoint: data?.spec?.endpoint || "",
      username: data?.spec?.auth?.username || "",
      password: data?.spec?.auth?.password || "",
      noAuth: data?.spec?.auth?.type === "noAuth",
      isValid: !!ociRegistryModal?.data?.uid,
      persisted: !!ociRegistryModal?.data?.uid,
      authType: data?.type || "basic",
      defaultRegion: data?.spec?.defaultRegion || "",
      providerType: data?.spec?.providerType || "helm",
      credentialType: data?.spec?.credentials?.credentialType || "secret",
      isPrivate: data?.spec?.isPrivate || false,
      enableSync: data?.status?.syncStatus?.isSyncSupported || false,
      baseContentPath: data?.spec?.baseContentPath || "",
      basePath: data?.spec?.basePath || "",
      helmBaseContentPath: data?.spec?.baseContentPath
        ? data?.spec?.baseContentPath.split(",")
        : [],
      arn: data?.spec?.credentials?.sts?.arn || "",
      externalId: stsData.externalId,
      accountId: stsData.accountId,
      accessKey: data?.spec?.credentials?.accessKey || "",
      secretKey: data?.spec?.credentials?.secretKey || "",
      insecureSkipTlsVerify:
        data?.spec?.auth?.tls?.insecureSkipVerify ||
        data?.spec?.tls?.insecureSkipVerify ||
        false,
    });
  },
  submit: async (data) => {
    const selectedRegistry = getSelectedOCIRegistry(store.getState());
    const isDevMode = store.getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "oci" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "oci" });
    const payload = getSubmitPayload({ data, isDevMode, isOCI: true });

    let response;
    try {
      response = selectedRegistry
        ? await api.put(
            `v1/registries/oci/${selectedRegistry.metadata.uid}/${data.authType}`,
            payload
          )
        : await api.post(`v1/registries/oci/${data.authType}`, payload);

      notifications.success({
        message: selectedRegistry
          ? i18n.t("OCI registry '{{name}}' was updated successfully", {
              name: data.name,
            })
          : i18n.t("OCI registry '{{name}}' was created successfully", {
              name: data.name,
            }),
      });
    } catch (error) {
      const message = selectedRegistry
        ? i18n.t("Something went wrong when editing the OCI registry")
        : i18n.t("Something went wrong when creating the OCI registry");

      notifications.error({
        message,
        description: error.message,
      });

      return Promise.reject();
    }

    if (selectedRegistry) {
      store.dispatch(ociRegistriesActions.initialize(OCI_MODULE));
      historyService.push(returnPath);
      return;
    }

    if (!response) {
      return;
    }

    historyService.push(returnPath);
    store.dispatch(
      ociRegistriesActions.addItems({
        module: OCI_MODULE,
        items: [
          {
            metadata: {
              name: data.name,
              uid: response.uid,
            },
            spec: {
              endpoint: data.endpoint,
            },
          },
        ],
      })
    );
  },
  validator: ociValidator,
});

export function validateOciRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.ociregistries?.data;
    if (!formData) {
      return;
    }

    const errors = await dispatch(
      ociRegistryFormActions.validateForm({
        module: OCI_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const selectedRegistryId = getSelectedOCIRegistry(store.getState())
      ?.metadata?.uid;

    const payload = getValidationPayload(formData, selectedRegistryId);
    const promise = api.post(
      `v1/registries/oci/${formData.authType}/validate`,
      payload
    );

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your helm registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onOciChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: OCI_MODULE };
    const isRegistryValidated = getState().registry.isValid;
    const formData = getState().forms?.[OCI_MODULE]?.data;

    if (
      isRegistryValidated &&
      ["pack", "helm"].includes(formData.providerType) &&
      !["name", "providerType"].includes(name)
    ) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    dispatch(ociRegistryFormActions.onChange(props));

    if (name === "providerType" && value === "zarf") {
      dispatch(
        ociRegistryFormActions.batchChange({
          updates: {
            authType: "basic",
            noAuth: true,
          },
          module: OCI_MODULE,
        })
      );
    }

    if (name === "providerType") {
      dispatch(
        ociRegistryFormActions.onChange({
          module: OCI_MODULE,
          name: "enableSync",
          value: value === "pack",
        })
      );
    }
  };
}

export function openOciRegistryModal(uid, ociType) {
  return (dispatch, getState) => {
    const isDevMode = getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "oci" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "oci" });

    ociRegistryModal.open({ uid, ociType }).then(
      () => {
        return dispatch(ociRegistryFormActions.submit({ module: OCI_MODULE }));
      },
      () => historyService.push(returnPath)
    );
    dispatch(ociRegistryFormActions.init({ module: OCI_MODULE }));
  };
}
